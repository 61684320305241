import React, { lazy, memo, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from '@hooks/store';
import RoleGuard from '@router/RoleGuard';
import { ROUTES, ROUTES_ALLOWED_ROLES } from '@router/routes';
import { selectAccessToken } from '@store/slices/authSlice';
import { Project } from '@views/Project';
import { ProjectHome } from '@views/ProjectHome';

const CustomLoader = lazy(() =>
  import('@components/CustomLoader').then((module) => ({
    default: module.CustomLoader,
  })),
);

const AllScenarios = lazy(() =>
  import('@views/AllScenarios').then((module) => ({
    default: module.AllScenarios,
  })),
);

const ConsultantProfile = lazy(() =>
  import('@views/Consultants/ConsultantProfile').then((module) => ({
    default: module.ConsultantProfile,
  })),
);

const Consultants = lazy(() =>
  import('@views/Consultants').then((module) => ({
    default: module.Consultants,
  })),
);
const Dashboard = lazy(() =>
  import('@views/Dashboard').then((module) => ({ default: module.Dashboard })),
);
const ForgotPassword = lazy(() =>
  import('@views/ForgotPassword').then((module) => ({
    default: module.ForgotPassword,
  })),
);
const ExpiredLink = lazy(() =>
  import('@views/ForgotPassword/ExpiredLink').then((module) => ({
    default: module.ExpiredLink,
  })),
);
const Insights = lazy(() =>
  import('@views/Insights').then((module) => ({ default: module.Insights })),
);
const InvitationLogin = lazy(() =>
  import('@views/InvitationLogin').then((module) => ({
    default: module.InvitationLogin,
  })),
);
const Main = lazy(() =>
  import('@views/Main').then((module) => ({ default: module.Main })),
);
const OauthSuccess = lazy(() =>
  import('@views/OauthSuccess').then((module) => ({
    default: module.OauthSuccess,
  })),
);
const Operations = lazy(() =>
  import('@views/Operations').then((module) => ({
    default: module.Operations,
  })),
);

const ProjectDashboard = lazy(() =>
  import('@views/ProjectDashboard').then((module) => ({
    default: module.ProjectDashboard,
  })),
);
const Projects = lazy(() => import('@views/Projects'));
const ProjectSettings = lazy(() =>
  import('@views/ProjectSettings').then((module) => ({
    default: module.ProjectSettings,
  })),
);
const ActivityHistory = lazy(() =>
  import('@views/ProjectSettings/components/ActivityHistory').then(
    (module) => ({ default: module.ActivityHistory }),
  ),
);
const EnterpriseSettings = lazy(
  () => import('@views/ProjectSettings/components/EnterpriseSettings'),
);
const ProjectDetailsSettings = lazy(
  () => import('@views/ProjectSettings/components/ProjectDetailsSettings'),
);
const ProjectModes = lazy(() =>
  import('@views/ProjectSettings/components/ProjectModes').then((module) => ({
    default: module.ProjectModes,
  })),
);
const Users = lazy(() =>
  import('@views/ProjectSettings/components/Users').then((module) => ({
    default: module.Users,
  })),
);
const RiskRegister = lazy(() =>
  import('@views/RisksRegister').then((module) => ({
    default: module.RiskRegister,
  })),
);
const ServiceTierDetails = lazy(() => import('@views/ServiceTierDetails'));
const Support = lazy(() =>
  import('@views/Support').then((module) => ({ default: module.Support })),
);
const UserProfile = lazy(() =>
  import('@views/UserProfile').then((module) => ({
    default: module.UserProfile,
  })),
);
const Login = lazy(() =>
  import('@views/Login').then((module) => ({ default: module.Login })),
);

const Questionnaire = lazy(() =>
  import('@views/Questionnaire').then((module) => ({
    default: module.default,
  })),
);

const QuestionnaireCompleted = lazy(() =>
  import('@views/QuestionnaireCompleted').then((module) => ({
    default: module.default,
  })),
);

const CiaReport = lazy(() =>
  import('@views/CiaReport').then((module) => ({
    default: module.default,
  })),
);

function PublicRoutes() {
  return (
    <>
      <Route index element={<Navigate to={ROUTES.LOGIN} />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTES.INVITATION_LOGIN} element={<InvitationLogin />} />
      <Route path={ROUTES.LOGIN_OAUTH} element={<OauthSuccess />} />
      <Route path={ROUTES.INVALID_LINK} element={<ExpiredLink />} />

      <Route path="*" element={<Navigate to={ROUTES.MAIN} replace />} />
    </>
  );
}

const ProjectProvider = lazy(() => import('@context/Project/ProjectProvider'));

const DashboardProvider = lazy(
  () => import('@context/DashboardContext/DashboardProvider'),
);

const ProjectFiltersProvider = lazy(
  () => import('@context/ProjectFilters/ProjectFiltersProvider'),
);

const ChangesProvider = lazy(() => import('@context/Changes/ChangesProvider'));

function PrivateRoutes() {
  return (
    <>
      <Route path={ROUTES.CIA_REPORT} element={<CiaReport />} />

      <Route
        path={ROUTES.MAIN}
        element={
          <Suspense fallback={<CustomLoader open />}>
            <Main />
          </Suspense>
        }
      >
        <Route
          path={ROUTES.DASHBOARD}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <RoleGuard allowedRoles={ROUTES_ALLOWED_ROLES.DASHBOARD}>
                <Dashboard />
              </RoleGuard>
            </Suspense>
          }
        />

        <Route
          path={ROUTES.CONSULTANTS}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <RoleGuard allowedRoles={ROUTES_ALLOWED_ROLES.CONSULTANTS}>
                <Consultants />
              </RoleGuard>
            </Suspense>
          }
        />
        <Route
          path={ROUTES.CONSULTANT_PROFILE}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <RoleGuard allowedRoles={ROUTES_ALLOWED_ROLES.CONSULTANT_PROFILE}>
                <ConsultantProfile />
              </RoleGuard>
            </Suspense>
          }
        />
        <Route
          path={ROUTES.SUPPORT}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <Support />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.PROJECT.LIST}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <RoleGuard allowedRoles={ROUTES_ALLOWED_ROLES.PROJECT.LIST}>
                <Projects />
              </RoleGuard>
            </Suspense>
          }
        />

        <Route
          path={ROUTES.OPERATIONS.MAIN}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <RoleGuard allowedRoles={ROUTES_ALLOWED_ROLES.OPERATIONS.MAIN}>
                <Navigate to={ROUTES.OPERATIONS.QNOUS_REFERENCE} />
              </RoleGuard>
            </Suspense>
          }
        />

        <Route
          path={ROUTES.OPERATIONS.TAB}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <Operations />
            </Suspense>
          }
        />

        <Route
          path={ROUTES.OPERATIONS.SERVICE_TIER_DETAILS}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <ServiceTierDetails />
            </Suspense>
          }
        />

        <Route
          path={ROUTES.PROJECT.SCENARIO.HOME}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <ProjectHome />
            </Suspense>
          }
        >
          <Route
            path={ROUTES.PROJECT.SCENARIO.DASHBOARD}
            element={<ProjectDashboard />}
          />
          <Route
            path={ROUTES.PROJECT.SCENARIO.MODEL}
            element={<Project />}
            index
          />
          <Route
            path={ROUTES.PROJECT.SCENARIO.INSIGHTS}
            element={<Insights />}
          />
          <Route
            path={ROUTES.PROJECT.SCENARIO.RISKS}
            element={<RiskRegister />}
          />
        </Route>

        <Route
          path={ROUTES.PROJECT.HOME}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <ProjectHome />
            </Suspense>
          }
        >
          <Route
            path={ROUTES.PROJECT.DASHBOARD}
            element={<ProjectDashboard />}
          />
          <Route path={ROUTES.PROJECT.MODEL} element={<Project />} />
          <Route path={ROUTES.PROJECT.INSIGHTS} element={<Insights />} />
          <Route path={ROUTES.PROJECT.RISKS} element={<RiskRegister />} />
        </Route>
        <Route
          path={ROUTES.PROJECT.QUESTIONNAIRE}
          element={<Questionnaire />}
        />

        <Route
          path={ROUTES.PROJECT.QUESTIONNAIRE_COMPLETED}
          element={<QuestionnaireCompleted />}
        />

        <Route
          path={ROUTES.PROJECT_SETTINGS.MAIN}
          element={
            <Suspense fallback={<CustomLoader open />}>
              <ProjectSettings />
            </Suspense>
          }
        >
          <Route
            path={ROUTES.PROJECT_SETTINGS.MODES}
            element={<ProjectModes />}
          />
          <Route path={ROUTES.PROJECT_SETTINGS.USERS} element={<Users />} />
          <Route
            path={ROUTES.PROJECT_SETTINGS.ENTERPRISE_SETTINGS}
            element={
              <RoleGuard
                allowedRoles={
                  ROUTES_ALLOWED_ROLES.PROJECT_SETTINGS.ENTERPRISE_SETTINGS
                }
              >
                <EnterpriseSettings />
              </RoleGuard>
            }
          />
          <Route
            path={ROUTES.PROJECT_SETTINGS.PROJECT_SETTINGS}
            element={
              <RoleGuard
                allowedRoles={
                  ROUTES_ALLOWED_ROLES.PROJECT_SETTINGS.PROJECT_SETTINGS
                }
              >
                <ProjectDetailsSettings />
              </RoleGuard>
            }
          />

          <Route
            path={ROUTES.PROJECT_SETTINGS.ACTIVITY_HISTORY}
            element={
              <RoleGuard
                allowedRoles={
                  ROUTES_ALLOWED_ROLES.PROJECT_SETTINGS.ACTIVITY_HISTORY
                }
              >
                <ActivityHistory />
              </RoleGuard>
            }
          />
        </Route>

        <Route
          path={ROUTES.PROJECT.SCENARIOS}
          element={
            <Suspense fallback={null}>
              <AllScenarios />
            </Suspense>
          }
        />

        <Route
          path={ROUTES.USER_PROFILE}
          element={
            <Suspense fallback={null}>
              <UserProfile />
            </Suspense>
          }
        />

        <Route path="*" element={<Navigate to={ROUTES.MAIN} />} />
      </Route>

      <Route index path="*" element={<Navigate to={ROUTES.MAIN} replace />} />
    </>
  );
}

export const Router = memo(() => {
  const accessToken = useAppSelector(selectAccessToken);

  if (accessToken) {
    return (
      <ChangesProvider>
        <ProjectProvider>
          <ProjectFiltersProvider>
            <DashboardProvider>
              <Routes>{PrivateRoutes()}</Routes>
            </DashboardProvider>
          </ProjectFiltersProvider>
        </ProjectProvider>
      </ChangesProvider>
    );
  }

  return (
    <Suspense fallback={null}>
      <Routes>{PublicRoutes()}</Routes>
    </Suspense>
  );
});
