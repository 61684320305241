import { RelationContextType } from '@components/EntityDrawers/drawers/Relationship/constants/types';
import useStore from '@components/MainStage/store';
import {
  ComponentSubLayerTypes,
  LogicalSubLayerTypes,
  PhysicalSubLayerTypes,
} from '@constants/canvas/layers';
import { canvasMap } from '@utils/canvas/CanvasMap';

const useContextType = (): RelationContextType | null => {
  const { getActiveEdge } = useStore();
  const activeEdge = getActiveEdge();

  const parent = activeEdge?.source
    ? canvasMap.nodeMap.get(activeEdge?.source)
    : null;

  const child = activeEdge?.target
    ? canvasMap.nodeMap.get(activeEdge?.target)
    : null;

  if (!parent || !child) return null;

  if (
    parent.parentNode === LogicalSubLayerTypes.Services &&
    child.parentNode === PhysicalSubLayerTypes.Capabilities
  ) {
    return RelationContextType.ServiceCapability;
  }

  if (
    parent.parentNode === PhysicalSubLayerTypes.Capabilities &&
    child.parentNode === PhysicalSubLayerTypes.Functions
  ) {
    return RelationContextType.CapabilityFunction;
  }

  if (
    parent.parentNode === PhysicalSubLayerTypes.Functions &&
    child.parentNode === ComponentSubLayerTypes.Technologies
  ) {
    return RelationContextType.FunctionTechnology;
  }

  return null;
};

export default useContextType;
