import React, { FC, forwardRef, useRef } from 'react';
import Label from '@components/Inputs/Label';
import { SelectItemProps } from '@components/Inputs/TextFieldSelect/types';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { ISelectOption } from '@constants/entities/ui';
import useToggle from '@hooks/useToggle';
import {
  Box,
  MenuItem,
  SelectProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { DropdownIcon } from '@utils/iconsDefs';

export type TextFieldSelectProps = Pick<
  TextFieldProps,
  | 'value'
  | 'onChange'
  | 'error'
  | 'helperText'
  | 'disabled'
  | 'required'
  | 'placeholder'
  | 'label'
  | 'sx'
> & {
  options?: ISelectOption[] | null;
  resetOnDoubleClick?: boolean;
  itemComponent?: FC<SelectItemProps & any>;
  itemProps?: (option: ISelectOption) => Record<string, any>;
  selectProps?: SelectProps;
  maxMenuHeight?: number;
  maxMenuWidth?: number;
};

export const DEFAULT_PLACEHOLDER_VALUE = 'default';

const TextFieldSelect = forwardRef<any, TextFieldSelectProps>(
  (
    {
      label,
      placeholder,
      options,
      required,
      value,
      resetOnDoubleClick,
      itemComponent: ItemComponent = ({ label }) => (
        <TypographyWithElipsis color="inherit">{label}</TypographyWithElipsis>
      ),
      itemProps,
      selectProps = {},
      maxMenuHeight = 210,
      maxMenuWidth,
      sx,
      ...props
    },
    ref,
  ) => {
    const [open, { on, off }] = useToggle();

    const emptyRef = useRef<HTMLLIElement>(null);

    const handleClickOption = (newValue: string | number) => () => {
      const isReset = resetOnDoubleClick && value === newValue;
      if (isReset) emptyRef?.current?.click();
    };

    const getArrowColor = () => {
      if (props.disabled) return 700;
      if (value === DEFAULT_PLACEHOLDER_VALUE) return 900;
      return 1000;
    };

    return (
      <Box width="100%" sx={sx}>
        {label && <Label required={required}>{label}</Label>}

        <TextField
          ref={ref}
          sx={{ width: '100%' }}
          select
          value={value}
          className={
            value === DEFAULT_PLACEHOLDER_VALUE ||
            (selectProps.multiple && !(value as any[]).length)
              ? 'placeholder'
              : ''
          }
          SelectProps={{
            open,
            onClose: () => {
              off();

              setTimeout(() => {
                (document.activeElement as HTMLInputElement)?.blur();
              }, 0);
            },

            onOpen: on,

            renderValue: (selectedValue) => {
              if (selectedValue === DEFAULT_PLACEHOLDER_VALUE) {
                return placeholder;
              }

              if (Array.isArray(selectedValue)) {
                if (!selectedValue.length) return placeholder;

                return selectedValue
                  .map(
                    (item) =>
                      options?.find((option) => option.value === item)?.label,
                  )
                  .join(', ');
              }

              return options?.find(({ value }) => value === selectedValue)
                ?.label;
            },

            // eslint-disable-next-line react/no-unstable-nested-components
            IconComponent: (props) => (
              <DropdownIcon
                {...props}
                style={{ right: 12, top: 12 }}
                fill={themePalette.grey[getArrowColor()]}
              />
            ),

            ...selectProps,

            MenuProps: {
              sx: {
                '& .MuiPaper-root': {
                  maxHeight: maxMenuHeight,
                  maxWidth: maxMenuWidth,
                },
              },
              ...selectProps?.MenuProps,
            },
          }}
          {...props}
        >
          {placeholder && (
            <MenuItem
              disabled
              sx={{ display: 'none' }}
              value={DEFAULT_PLACEHOLDER_VALUE}
            >
              {placeholder}
            </MenuItem>
          )}

          <MenuItem ref={emptyRef} disabled sx={{ display: 'none' }} value="" />

          {options?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={handleClickOption(option.value)}
              className="select-option"
              sx={{ overflowX: 'hidden' }}
              disabled={option.disabled}
              tabIndex={-1}
            >
              <ItemComponent
                value={option.value}
                label={option.label}
                disabled={option.disabled}
                onCloseMenu={off}
                {...(itemProps?.(option) ?? {})}
              />
            </MenuItem>
          ))}

          {!options?.length && (
            <MenuItem disabled value="">
              No options
            </MenuItem>
          )}
        </TextField>
      </Box>
    );
  },
);

export default TextFieldSelect;
