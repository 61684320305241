import {
  ConceptualSourceFormValues,
  ConceptualSourceObject,
  LogicalPolicyFormValues,
  PhysicalRulesFormValues,
  PolicyObject,
  RuleObject,
} from '@components/EntityDrawers/constants/empty-objects';
import {
  commonDefaults,
  commonTransformers,
  commonYups,
} from '@components/EntityDrawers/constants/fields';
import {
  CommonKeys,
  LogicPhysicalFormKeysType,
} from '@components/EntityDrawers/constants/keys';
import { TransformContext } from '@components/EntityDrawers/hooks/useTransformContext';
import { DateFormats } from '@constants/dates';
import { ISelectOption } from '@constants/entities/ui';
import { OmitId, UnionFromArray } from '@constants/types';
import { nanoid } from '@reduxjs/toolkit';
import {
  AssuranceControl,
  AssuranceControlCriteria,
  CIACriteriaItemListType,
  CIACriteriaItemType,
  ConceptualSource,
  LogicalPolicy,
  NameObjective,
  NodeCiaFields,
  NodeDTO,
  Rule,
} from '@store/services/nodes/types';
import { CIASettingsCriteriaType } from '@store/services/questionnaire/types';
import {
  filterEmptyAndOmitId,
  omitCertainEmptyKeys,
  transformArrayToOptions,
  withId,
} from '@utils/helpers';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import { ObjectShape } from 'yup';

export const isElementLocal = (id: string) => id.startsWith('local');

export const getYup = (key: CommonKeys) => ({ [key]: commonYups[key] });
export const getDef = (key: CommonKeys) => ({ [key]: commonDefaults[key] });

export const getYups = <T extends CommonKeys>(...keys: T[]) => {
  return keys.reduce(
    (acc, key) => ({ ...acc, ...getYup(key) }),
    {} as { [K in (typeof keys)[number]]: (typeof commonYups)[K] },
  );
};

export const getDefs = <T extends CommonKeys>(...keys: T[]) => {
  return keys.reduce(
    (acc, key) => ({ ...acc, ...getDef(key) }),
    {} as { [K in (typeof keys)[number]]: (typeof commonDefaults)[K] },
  );
};

export const transformCommonKeys =
  <FormValues, Meta>(...keys: CommonKeys[]) =>
  (node: NodeDTO<Meta>, context: TransformContext) => {
    return keys.reduce(
      (acc, key) => ({ ...acc, [key]: commonTransformers[key](node, context) }),
      {} as FormValues,
    );
  };

export const stringToDayjs = (date: string | undefined | null) =>
  date ? dayjs(date) : null;

export const prepareDate = (date: Dayjs | undefined | null, key: string) =>
  date?.isValid() ? { [key]: date?.format(DateFormats[2]) } : {};

const transformCriteriaObjectToArray = (
  criteria?: Record<string, ISelectOption[]>,
) => {
  if (!criteria) return [];

  return (Object.entries(criteria) as [string, ISelectOption[]][]).reduce<
    CIACriteriaItemType[]
  >((acc, [id, answers]) => {
    if (answers === undefined || !Array.isArray(answers)) return acc;

    return [
      ...acc,
      { id, answers: answers.map(({ value }) => value as string) },
    ];
  }, [] as CIACriteriaItemType[]);
};

type PrepareCiaType = {
  assurance_level?: Record<string, ISelectOption[]>;
  coverage?: Record<string, ISelectOption[]>;
  applicability?: Record<string, ISelectOption[]>;
};

export const prepareCia = ({
  assurance_level,
  coverage,
  applicability,
}: PrepareCiaType): NodeCiaFields => ({
  assurance_level: {
    criterias: transformCriteriaObjectToArray(assurance_level),
  },
  coverage: {
    criterias: transformCriteriaObjectToArray(coverage),
  },
  applicability: {
    criterias: transformCriteriaObjectToArray(applicability),
  },
});

export const prepareBasicLogicPhysicalData = (
  values: Partial<Record<UnionFromArray<LogicPhysicalFormKeysType>, any>>,
) => ({
  ...omitCertainEmptyKeys(values, [
    CommonKeys.OperationalStatus,
    CommonKeys.OperationalModel,
    CommonKeys.OperationalEffectiveness,
    CommonKeys.DesignEffectiveness,
  ]),

  ...prepareDate(values[CommonKeys.TimelineStartDate], 'start_date'),
  ...prepareDate(values[CommonKeys.TimelineEndDate], 'end_date'),

  metric: filterEmptyAndOmitId<NameObjective>(values[CommonKeys.Metrics]),

  ...(values[CommonKeys.CurrentMaturity]
    ? {
        current_maturity: parseFloat(
          values[CommonKeys.CurrentMaturity].toString().replace(',', '.'),
        ).toString(),
      }
    : {}),

  ...(values[CommonKeys.TargetMaturity]
    ? {
        target_maturity: parseFloat(
          values[CommonKeys.TargetMaturity].toString().replace(',', '.'),
        ).toString(),
      }
    : {}),
});

export const generateSchema = <T extends ObjectShape>(shape: any) =>
  yup.object().shape<T>(shape);

export const checkValuesIncomplete =
  <Values>(...keys: (keyof Values)[]) =>
  (values: Partial<Values>) => {
    return keys.some((key) => {
      const value = values[key];

      if (Array.isArray(value)) {
        return !value.length;
      }

      if (typeof value === 'string') {
        return !value.trim().length;
      }

      if (dayjs.isDayjs(value)) {
        return !value.isValid();
      }

      return !value;
    });
  };

export const checkArrayFieldsIncomplete = <Item>(
  array: Item[],
  requiredKeys: (keyof Item)[],
) => {
  return array.some((item) =>
    checkValuesIncomplete<Item>(...requiredKeys)(item),
  );
};

export const add = (isValid: boolean, obj: any) => (isValid ? obj : {});

export const parseCiaCriteria = (
  criteria: CIACriteriaItemListType | undefined,
  originCriteria: CIASettingsCriteriaType[],
) => {
  return Object.fromEntries([
    ...originCriteria.map(({ id }) => [id, null]),
    ...(criteria?.criterias ?? []).map(({ id, answers }) => [
      id,
      transformArrayToOptions(answers),
    ]),
  ]);
};

// TODO: Refactor all transform..ToFormValues and transform...FormValuesToMetaData

export const transformRulesToFormValues = (
  arr?: OmitId<Rule>[],
): PhysicalRulesFormValues[] => {
  return arr?.length
    ? arr.map(({ link, control, name }) =>
        withId({
          name: name ?? '',
          link: link ?? '',
          control: control?.length
            ? control
            : [
                withId({
                  name: '',
                  same_as_entity_assurance: false,
                  criterias: [] as AssuranceControlCriteria[],
                }),
              ],
        }),
      )
    : [
        withId({
          ...RuleObject,
          control: [
            withId({
              name: '',
              same_as_entity_assurance: false,
              criterias: [] as AssuranceControlCriteria[],
            }),
          ],
        }),
      ];
};

export const transformRulesFormValuesToMetaData = (
  arr: PhysicalRulesFormValues[],
) => {
  return arr.map((rule) => {
    const rulesMetaData: OmitId<Rule> = {
      ...rule,
      control: [],
    };

    if (rulesMetaData.control) {
      rulesMetaData.control = rule.control.reduce<AssuranceControl[]>(
        (acc, req) => {
          if (req.name.trim().length || req.criterias.length) {
            const { id, ...reqWithoutId } = req;

            return [
              ...acc,
              {
                ...reqWithoutId,
                id: (id.length === NANOID_LENGTH ? undefined : id) as string,
              },
            ];
          }
          return acc;
        },
        [] as AssuranceControl[],
      );
    }

    const { control, ...rest } = rulesMetaData;

    const filledEntries = Object.entries(rest).filter(
      ([, value]) => value?.toString().trim() !== '',
    );

    return {
      control,
      ...Object.fromEntries(filledEntries),
    };
  });
};

export const transformPolicyToFormValues = (
  arr?: LogicalPolicy[],
): LogicalPolicyFormValues[] => {
  return arr?.length
    ? arr.map(({ name, requirement, link }) =>
        withId({
          name: name ?? '',
          link: link ?? '',
          requirement: requirement?.length
            ? requirement
            : [
                withId({
                  name: '',
                  same_as_entity_assurance: false,
                  criterias: [] as AssuranceControlCriteria[],
                }),
              ],
        }),
      )
    : [
        withId({
          ...PolicyObject,
          requirement: [
            withId({
              name: '',
              same_as_entity_assurance: false,
              criterias: [] as AssuranceControlCriteria[],
            }),
          ],
        }),
      ];
};

const NANOID_LENGTH = 10;

export const transformPolicyFormValuesToMetaData = (
  arr: LogicalPolicyFormValues[],
) => {
  return arr.map((source) => {
    const policyMetaData: OmitId<LogicalPolicy> = {
      ...source,
      requirement: [],
    };

    if (policyMetaData.requirement) {
      policyMetaData.requirement = source.requirement.reduce<
        AssuranceControl[]
      >((acc, req) => {
        if (req.name.trim().length || req.criterias.length) {
          const { id, ...reqWithoutId } = req;

          return [
            ...acc,
            {
              ...reqWithoutId,
              id: (id.length === NANOID_LENGTH ? undefined : id) as string,
            },
          ];
        }
        return acc;
      }, [] as AssuranceControl[]);
    }

    const { requirement, ...rest } = policyMetaData;

    const filledEntries = Object.entries(rest).filter(
      ([, value]) => value?.toString().trim() !== '',
    );

    return {
      requirement,
      ...Object.fromEntries(filledEntries),
    };
  });
};

export const transformSourcesToFormValues = (arr?: ConceptualSource[]) => {
  return arr?.length
    ? arr.map((e) => ({
        ...withId(e),
        justification_statement: (e.justification_statement?.length
          ? e.justification_statement
          : ['']
        ).map((js) => ({ id: nanoid(10), name: js })),
      }))
    : [
        {
          id: nanoid(10),
          ...ConceptualSourceObject,
          justification_statement: [{ id: nanoid(10), name: '' }],
        },
      ];
};

export const transformSourcesFormValuesToMetaData = (
  arr: ConceptualSourceFormValues[],
) => {
  return arr.map((source) => {
    const sourceMetaData: any = { ...source };

    if (sourceMetaData.justification_statement) {
      sourceMetaData.justification_statement = source.justification_statement
        .map((js) => js.name.trim())
        .filter((js) => js.length);
    }

    const { id, justification_statement, ...rest } = sourceMetaData;

    const filledEntries = Object.entries(rest).filter(
      ([, value]) => value?.toString().trim() !== '',
    );

    return {
      justification_statement,
      ...Object.fromEntries(filledEntries),
    };
  });
};
