import { useMemo } from 'react';
import RequirementOptionsController from '@components/EntityDrawers/drawers/Relationship/components/RelationshipContext/utils/RequirementOptionsController';
import {
  RelationContextType,
  RelationOption,
} from '@components/EntityDrawers/drawers/Relationship/constants/types';
import useStore from '@components/MainStage/store';
import { canvasMap } from '@utils/canvas/CanvasMap';

type ReturnType = {
  parentOptions: RelationOption[];
  childOptions: RelationOption[];
};

const useContextOptions = (type: RelationContextType | null): ReturnType => {
  const { getActiveEdge } = useStore();
  const activeEdge = getActiveEdge();

  return useMemo(() => {
    const parent = activeEdge?.source
      ? canvasMap.nodeMap.get(activeEdge?.source)
      : null;

    const child = activeEdge?.target
      ? canvasMap.nodeMap.get(activeEdge?.target)
      : null;

    if (!parent || !child) return { parentOptions: [], childOptions: [] };

    switch (type) {
      case RelationContextType.ServiceCapability: {
        return {
          parentOptions: RequirementOptionsController.getRelationOptions(
            parent.data.dto.meta_data.policy,
            'requirement',
          ),
          childOptions: RequirementOptionsController.getRelationOptions(
            child.data.dto.meta_data.rules,
            'control',
          ),
        };
      }

      case RelationContextType.CapabilityFunction: {
        return {
          parentOptions: RequirementOptionsController.getRelationOptions(
            parent.data.dto.meta_data.rules,
            'control',
          ),
          childOptions: RequirementOptionsController.getRelationOptions(
            child.data.dto.meta_data.rules,
            'control',
          ),
        };
      }

      case RelationContextType.FunctionTechnology: {
        return {
          parentOptions: RequirementOptionsController.getRelationOptions(
            parent.data.dto.meta_data.rules,
            'control',
          ),
          childOptions: [],
        };
      }

      default:
        return {
          parentOptions: [],
          childOptions: [],
        };
    }
  }, [type, activeEdge]);
};

export default useContextOptions;
