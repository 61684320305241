import { useFormContext, useWatch } from 'react-hook-form';
import { RelationshipFormValues } from '@components/EntityDrawers/drawers/Relationship/form';

const useBlockControls = (id: string) => {
  const { setValue } = useFormContext<RelationshipFormValues>();

  const context = useWatch<RelationshipFormValues, 'context'>({
    name: 'context',
  });

  const isOn = Array.isArray(context[id]);

  const handleToggle = (turnOn: boolean) => {
    setValue(`context.${id}` as any, turnOn ? [] : null, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const value = context[id]?.[0];

  const handleChangeValue = (value: string) => {
    setValue(`context.${id}` as any, [value], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return { isOn, handleToggle, value, handleChangeValue };
};

export default useBlockControls;
