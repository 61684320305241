import { RelationOption } from '@components/EntityDrawers/drawers/Relationship/constants/types';
import { AssuranceControl } from '@store/services/nodes/types';

class RequirementOptionsController {
  static getRelationOptions(
    controlsParent: any[],
    controlKey: string,
  ): RelationOption[] {
    return controlsParent.reduce((acc, control) => {
      (control[controlKey] as AssuranceControl[]).forEach(({ name, id }) => {
        acc.push({ name, id });
      });

      return acc;
    }, [] as RelationOption[]);
  }
}

export default RequirementOptionsController;
