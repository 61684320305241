import { UseFormReturn } from 'react-hook-form';
import { RelationshipFormValues } from '@components/EntityDrawers/drawers/Relationship/form';
import useStore from '@components/MainStage/store';
import { WeightTypes } from '@constants/entities/relationship';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import useAssets from '@hooks/useAssets';
import useSaveEntitiesResponse from '@hooks/useSaveEntitiesResponse';
import {
  useCreateRelationshipMutation,
  useUpdateRelationshipMutation,
} from '@store/services/nodes';
import { IUpdateRelationshipRequest } from '@store/services/nodes/types';
import { cutNumberPeriods, parseErrorResponse } from '@utils/helpers';

type SaveRelationshipOptions = {
  form: UseFormReturn<RelationshipFormValues>;
  editMode?: boolean;
};

const useSaveRelationship = ({
  form: { getValues },
  editMode,
}: SaveRelationshipOptions) => {
  const { getActiveEdge } = useStore();
  const activeEdge = getActiveEdge();
  const { showToast } = useToast();
  const {
    hideDrawer,
    toolbox: { mode },
  } = useProject();
  const { selectedAssetId } = useAssets();

  const saveResponseToRedux = useSaveEntitiesResponse();

  const [createRelationship, createState] = useCreateRelationshipMutation();
  const [updateRelationship, updateState] = useUpdateRelationshipMutation();

  const mutateRelationship = (
    baseBody: Omit<IUpdateRelationshipRequest, 'id'>,
  ) => {
    if (editMode) {
      return updateRelationship({ id: activeEdge?.id || '', ...baseBody });
    }

    return createRelationship({
      ...baseBody,
      child_id: activeEdge?.target || '',
      parent_id: activeEdge?.source || '',
    });
  };

  const handleSave = async () => {
    try {
      if (activeEdge) {
        const {
          type,
          dependency,
          description,
          weight,
          weight_manual,
          context,
        } = getValues();

        const baseBody = {
          mode,
          assetId: selectedAssetId || undefined,

          type,
          dependency,
          description: description || null,
          weight: parseFloat(
            cutNumberPeriods(parseFloat(weight.replace(',', '.'))),
          ),
          weight_type: weight_manual ? WeightTypes.Manual : WeightTypes.Auto,

          meta_data: { controls: context },

          ...(selectedAssetId && { asset_id: selectedAssetId }),
        } as Omit<IUpdateRelationshipRequest, 'id'>;

        const response = await mutateRelationship(baseBody).unwrap();
        saveResponseToRedux(response);

        hideDrawer();
      }
    } catch (error: any) {
      showToast(error.message || parseErrorResponse(error), 'error');
    }
  };

  return {
    handleSave,
    isLoading: createState.isLoading || updateState.isLoading,
  };
};

export default useSaveRelationship;
