export enum RelationContextType {
  ServiceCapability = 'ServiceCapability',
  CapabilityFunction = 'CapabilityFunction',
  FunctionTechnology = 'FunctionTechnology',
}

export type RelationOption = {
  id: string;
  name: string;
};
