import React, { FC, useEffect, useRef } from 'react';
import { SideMenu } from '@components/SideMenu';
import { useProject } from '@context/Project/ProjectProvider';
import { Button, Chip, IconButton, Stack, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import {
  CloseIcon,
  CompletenessIcon,
  DependencyIcon,
  FocusOnIcon,
  KeyControlsIcon,
  MaturityIcon,
  ModelEvolutionIcon,
} from '@utils/iconsDefs';

export enum DrawerBadges {
  FocusOn = 'Focus On',
  Dependency = 'Dependency',
  Maturity = 'Maturity',
  ModelEvolution = 'Model evolution',
  Completeness = 'Completeness',
  KeyControls = 'Key controls',
}

export type ProjectRightDrawerProps = {
  title: string;
  onClose?: () => void;
  onSave?: () => void;
  disabledSave?: boolean;
  children?: React.ReactNode;
  headChildren?: React.ReactNode;
  tab?: number;
  badge?: DrawerBadges;
};

const Badges = {
  [DrawerBadges.FocusOn]: (
    <Chip
      label="Focus on"
      color="primary"
      icon={<FocusOnIcon fill={themePalette.grey[900]} />}
    />
  ),
  [DrawerBadges.Dependency]: (
    <Chip
      label="Dependency"
      color="primary"
      icon={<DependencyIcon fill={themePalette.grey[900]} />}
    />
  ),
  [DrawerBadges.Maturity]: (
    <Chip
      label="Maturity"
      color="primary"
      icon={<MaturityIcon fill={themePalette.grey[900]} />}
    />
  ),
  [DrawerBadges.ModelEvolution]: (
    <Chip
      label="Model evolution"
      color="primary"
      icon={<ModelEvolutionIcon fill={themePalette.grey[900]} />}
    />
  ),
  [DrawerBadges.Completeness]: (
    <Chip
      label="Completeness"
      color="primary"
      icon={<CompletenessIcon fill={themePalette.grey[900]} />}
    />
  ),
  [DrawerBadges.KeyControls]: (
    <Chip
      label="Key controls"
      color="primary"
      icon={<KeyControlsIcon fill={themePalette.grey[900]} />}
    />
  ),
};

export const DRAWER_WIDTH = 415;

const ProjectRightDrawer: FC<ProjectRightDrawerProps> = ({
  title,
  onClose,
  onSave,
  disabledSave,
  children,
  headChildren,
  tab,
  badge,
}) => {
  const { viewOnly, isUnsavedModalOpen } = useProject();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollTo({ top: 0 });
  }, [tab]);

  const settingsRef = useRef({
    isUnsavedModalOpen,
    disabledSave,
  });

  useEffect(() => {
    settingsRef.current = {
      isUnsavedModalOpen,
      disabledSave,
    };
  }, [isUnsavedModalOpen, disabledSave]);

  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;

      const isComboboxOpen =
        target?.role === 'combobox' && target?.ariaExpanded === 'true';

      if (e.key === 'Enter') {
        if (e.shiftKey) {
          // Allow line break
          return;
        }

        const focusedElement = document.activeElement;

        // Check if the focused element is an input
        if (
          focusedElement &&
          (focusedElement.tagName === 'INPUT' ||
            focusedElement.tagName === 'TEXTAREA')
        ) {
          // Blur the input
          (focusedElement as HTMLInputElement).blur();

          setTimeout(() => {
            const isSaveAvailableAfterValidation =
              !settingsRef.current.isUnsavedModalOpen &&
              !settingsRef.current.disabledSave &&
              !isComboboxOpen;

            if (isSaveAvailableAfterValidation) onSave?.();
          }, 10);
        } else {
          const isSaveAvailable =
            !settingsRef.current.isUnsavedModalOpen &&
            !settingsRef.current.disabledSave &&
            !isComboboxOpen;

          if (isSaveAvailable) onSave?.();
        }
      }
    };

    window.addEventListener('keydown', handleEnter);

    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
  }, []);

  return (
    <SideMenu
      open
      side="right"
      width={DRAWER_WIDTH}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'white',
        zIndex: 10,
      }}
    >
      <Stack p={6} gap={6}>
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={2} alignItems="flex-start">
            {badge && Badges[badge]}

            <Typography variant="h2">{title}</Typography>
          </Stack>

          <IconButton onClick={onClose} sx={{ right: -0, p: '6px' }}>
            <CloseIcon fill={themePalette.grey[900]} />
          </IconButton>
        </Stack>

        {headChildren}
      </Stack>

      <Stack ref={ref} px={6} pb={6} flex={1} gap={6} overflow="auto">
        {children}
      </Stack>

      {!viewOnly && (
        <Stack
          p={6}
          gap={4}
          direction="row"
          borderTop={`1px solid ${themePalette.grey[500]}`}
        >
          <Button
            variant="outlined"
            size="large"
            sx={{ flex: 1 }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            size="large"
            sx={{ flex: 1 }}
            disabled={disabledSave}
            onClick={onSave}
          >
            Save
          </Button>
        </Stack>
      )}
    </SideMenu>
  );
};

export default ProjectRightDrawer;
