import { EntityValidationStatus, Modes } from '@constants/canvas/layers';
import {
  DependencyTypes,
  RelationshipTypes,
} from '@constants/entities/relationship';
import {
  MaxDescriptionLength,
  OnlyTwoSymbolsAfterComaValidation,
  YupString,
} from '@constants/validation';
import * as yup from 'yup';

export type RelationshipFormValues = yup.InferType<
  Omit<typeof validationSchema, 'context'>
> & {
  context: Record<string, string[] | null>;
};

export const validationSchema = yup.object().shape({
  type: yup.string(),
  dependency: yup.string().nonNullable(),
  description: YupString({
    min: 1,
    max: MaxDescriptionLength,
    allowParagraph: true,
  }),

  context: yup
    .object()
    .shape({
      // Validate an object where keys are strings and values are arrays of strings
    })
    .test('is-record', 'Invalid context format', (value) => {
      if (typeof value !== 'object' || value === null) return false;

      return Object.entries(value).every(
        ([key, val]) =>
          typeof key === 'string' &&
          (val === null ||
            (Array.isArray(val) && val.every((v) => typeof v === 'string'))),
      );
    }),

  weight: yup
    .string()
    .transform((_, o) => {
      return o?.trim() ? o?.replace(',', '.') : null;
    })
    .required('Field cannot be empty')
    .test(
      'weight',
      'Field may contain integer or decimal numbers with maximum 2 digits after comma or dot separator',
      function (value) {
        if (!value) return true;
        return OnlyTwoSymbolsAfterComaValidation.test(value);
      },
    )
    .test('weight-max', 'Weight cannot be greater than 100%', function (value) {
      const { weight_manual, maxWeight, dependency } = this.parent;

      if (!weight_manual || dependency !== DependencyTypes.AND) {
        return (parseFloat(value) || 0) <= 100;
      }

      return (
        (parseFloat(value) || 0) <= maxWeight ||
        this.createError({
          path: 'weight',
          message:
            'Can’t update weight. Sum of AND relationships weight values would exceed 100%',
        })
      );
    })
    .test('weight-min', 'Weight value should be minimum 1%', function (value) {
      const { dependency, weight_manual } = this.parent;
      if (dependency === DependencyTypes.AND && !weight_manual) {
        return true;
      }

      return (parseFloat(value) || 0) >= 1;
    }),

  weight_manual: yup.boolean().test({
    name: 'weight-manual',
    test(weight_manual) {
      const { isLastANDAuto } = this.parent;

      if (weight_manual && isLastANDAuto) {
        return this.createError({
          path: 'weight',
          message:
            "Can't update weight. This is the last auto-assigned AND relationship. Please set an auto-assigned weight for another AND relationship from the parent Entity first. ",
        });
      }

      return true;
    },
  }),

  valid_status: yup.string(),

  maxWeight: yup.number().nullable(),
  isLastANDAuto: yup.boolean(),
});

export const defaultRelationshipTypes = {
  [Modes.BusinessAlignment]: RelationshipTypes.SupportedBy,
  [Modes.Controls]: RelationshipTypes.SupportedBy,
  [Modes.RiskManagement]: RelationshipTypes.Has,
};

export const defaultValues: RelationshipFormValues = {
  type: '',
  dependency: DependencyTypes.AND,
  description: '',

  context: {},

  weight_manual: false,
  weight: '',

  valid_status: EntityValidationStatus.Draft,

  maxWeight: 100,
  isLastANDAuto: false,
};
