import { RelationContextType } from '@components/EntityDrawers/drawers/Relationship/constants/types';

export const MainLabels = {
  [RelationContextType.ServiceCapability]: 'Minimum requirement',
  [RelationContextType.CapabilityFunction]: 'Control objective',
  [RelationContextType.FunctionTechnology]: 'Control reference',
} as const;

export const UnderLabels = {
  [RelationContextType.ServiceCapability]: 'Underpinning control objectives',
  [RelationContextType.CapabilityFunction]: 'Underpinning control references',
  [RelationContextType.FunctionTechnology]: '-',
} as const;

export const UnderPlaceholders = {
  [RelationContextType.ServiceCapability]: 'Control objective',
  [RelationContextType.CapabilityFunction]: 'Control reference',
  [RelationContextType.FunctionTechnology]: '-',
} as const;
