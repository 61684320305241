import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  gap: theme.spacing(4),

  border: `1px solid ${theme.palette.grey['500']}`,
  borderRadius: theme.spacing(3),
}));
