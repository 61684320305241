import React, { FC, useMemo } from 'react';
import useFunctionControls from '@components/EntityDrawers/drawers/Relationship/components/RelationshipContext/hooks/useFunctionControls';
import { RelationOption } from '@components/EntityDrawers/drawers/Relationship/constants/types';
import TextFieldSelect from '@components/Inputs/TextFieldSelect';
import { transformArrayToOptions } from '@utils/helpers';

type Props = {
  prentOptions: RelationOption[];
};

const FunctionDropdown: FC<Props> = ({ prentOptions }) => {
  const { values, handleChangeValues } = useFunctionControls();

  const options = useMemo(
    () =>
      transformArrayToOptions(prentOptions, {
        label: 'name',
        value: 'id',
      }),
    [prentOptions],
  );

  return (
    <TextFieldSelect
      label="Function control references"
      placeholder="Control reference"
      options={options}
      selectProps={{ multiple: true, displayEmpty: true }}
      value={values}
      onChange={(e) =>
        handleChangeValues(e.target.value as unknown as string[])
      }
      maxMenuWidth={0}
    />
  );
};

export default FunctionDropdown;
