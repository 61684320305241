import React, { FC } from 'react';
import FunctionDropdown from '@components/EntityDrawers/drawers/Relationship/components/RelationshipContext/components/FunctionDropdown';
import RequirementBlock from '@components/EntityDrawers/drawers/Relationship/components/RelationshipContext/components/RequirementBlock';
import {
  RelationContextType,
  RelationOption,
} from '@components/EntityDrawers/drawers/Relationship/constants/types';
import {
  MainLabels,
  UnderLabels,
  UnderPlaceholders,
} from '@components/EntityDrawers/drawers/Relationship/constants/values';
import { Stack, Typography } from '@mui/material';

type Props = {
  type: RelationContextType;
  parentOptions: RelationOption[];
  childOptions: RelationOption[];
};

const RelationshipContext: FC<Props> = ({
  type,
  parentOptions,
  childOptions,
}) => {
  return (
    <Stack gap={4}>
      <Typography variant="h5">Relationship context</Typography>

      {type === RelationContextType.FunctionTechnology ? (
        <FunctionDropdown prentOptions={parentOptions} />
      ) : (
        parentOptions.map((option, index) => (
          <RequirementBlock
            key={option.id}
            mainLabel={`${MainLabels[type]} ${index + 1}`}
            underLabel={UnderLabels[type]}
            underPlaceholder={UnderPlaceholders[type]}
            option={option}
            childOptions={childOptions}
          />
        ))
      )}
    </Stack>
  );
};

export default RelationshipContext;
