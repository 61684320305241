import { useFormContext, useWatch } from 'react-hook-form';
import { RelationshipFormValues } from '@components/EntityDrawers/drawers/Relationship/form';

const useFunctionControls = () => {
  const { setValue } = useFormContext<RelationshipFormValues>();

  const context = useWatch<RelationshipFormValues, 'context'>({
    name: 'context',
  });

  const values = (Object.entries(context) ?? [])
    .filter(([_, val]) => Array.isArray(val))
    .map(([key]) => key);

  const handleChangeValues = (newValues: string[]) => {
    setValue(`context`, Object.fromEntries(newValues.map((v) => [v, []])), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return { values, handleChangeValues };
};

export default useFunctionControls;
