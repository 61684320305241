import React, { FC, useEffect, useRef, useState } from 'react';
import { CustomTooltip } from '@components/CustomTooltip';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { themePalette } from '@theme/muiTheme';
import { InfoCircleEmptyIcon } from '@utils/iconsDefs';

const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.palette.grey['700']};
  padding: 10px 12px;
  border-radius: 12px;
`;

const Text = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  flex: 1;
  color: ${({ theme }) => theme.palette.grey['800']};
`;

const StyledInfoIcon = styled(InfoCircleEmptyIcon)`
  fill: ${themePalette.grey['800']};
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

type Props = {
  value: string;
};

const RequirementNameInput: FC<Props> = ({ value }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      const { scrollHeight, clientHeight } = textAreaRef.current;
      setIsOverflowing(scrollHeight > clientHeight);
    }
  }, []);

  return (
    <Container>
      <Text ref={textAreaRef} variant="body1">
        {value}
      </Text>

      {isOverflowing && (
        <CustomTooltip title={value}>
          <StyledInfoIcon />
        </CustomTooltip>
      )}
    </Container>
  );
};

export default RequirementNameInput;
