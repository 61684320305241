import React, { FC } from 'react';
import ControlAssurance from '@components/EntityDrawers/components/ControlAssurance';
import {
  AssuranceControlObject,
  PolicyObject,
} from '@components/EntityDrawers/constants/empty-objects';
import { LogicalKeys } from '@components/EntityDrawers/drawers/Logical/keys';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups/ControlledInputGroups';
import ControlledLinkInput from '@components/Inputs/controllers/ControlledLinkInput';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';
import { getInputGroupsProps } from '@utils/helpers';

const MinimumRequirementInputs: FC<InputsBlockProps> = ({
  createName,
  viewOnly,
}) => {
  return (
    <>
      <ControlledTextField
        name={createName('name')}
        placeholder="Minimum requirement"
        disabled={viewOnly}
        multiline
        sx={{ mt: -3 }}
      />

      <ControlAssurance
        criteriaName={createName('criterias')}
        checkName={createName('same_as_entity_assurance')}
        controlName={createName('name')}
      />
    </>
  );
};

const PoliciesInputs: FC<InputsBlockProps> = ({ createName, viewOnly }) => (
  <>
    <ControlledTextField
      name={createName('name')}
      label="Policy name"
      placeholder="Policy name"
      disabled={viewOnly}
      multiline
    />

    <ControlledInputGroups
      name={createName(LogicalKeys.Requirement)}
      viewOnly={viewOnly}
      groupObject={AssuranceControlObject}
      titleProps={{ variant: 'h5' }}
      wrapped
      {...getInputGroupsProps('minimum requirement')}
    >
      {MinimumRequirementInputs}
    </ControlledInputGroups>

    <ControlledLinkInput
      name={createName('link')}
      label="Policy link"
      placeholder="Policy link"
      disabled={viewOnly}
      multiline
    />
  </>
);

const PoliciesGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledInputGroups
      name={LogicalKeys.Policies}
      viewOnly={viewOnly}
      groupObject={PolicyObject}
      {...getInputGroupsProps('policy')}
    >
      {PoliciesInputs}
    </ControlledInputGroups>
  );
};

export default PoliciesGroup;
