import { FC } from 'react';

export enum LoadingKeys {
  RecalculateLayersHeight = 'recalculate-layers-height',
  Collapse = 'collapse',
  PDFReport = 'PDFReport',
  PagedJS = 'PagedJS',
}

export interface ISelectOption<V = string | number> {
  label: string;
  value: V;
  icon?: FC<any>;
  disabled?: boolean;
}

export const NewItemPrefix = 'new-item-';

export const WideDomainKey = 'wide';

export enum OperationsTabs {
  QNOUS_REFERENCE = 'qnous-reference',
  SERVICE_TIERS = 'service-tiers',
}

export const MissingCIAError =
  'The assurance objectives have been added in the settings. Please upload a questionnaire file to \nactivate the CIA insight.';

export const InvalidCIAError =
  'The assurance objectives in the settings do not match those in the questionnaire file. Please upload \na new questionnaire file to activate the CIA insight.';

export const MissingCIADescription =
  'The assurance objectives have been added in \nthe settings. Please upload a questionnaire \nfile to activate the CIA insight.';

export const InvalidCIADescription =
  'The assurance objectives in the settings do \nnot match those in the questionnaire file. \nPlease upload a new questionnaire file to \nactivate the CIA insight.';
