import React, { FC, useMemo } from 'react';
import { Wrapper } from '@components/EntityDrawers/drawers/Relationship/components/RelationshipContext/components/RequirementBlock/RequirementBlock.styled';
import RequirementNameInput from '@components/EntityDrawers/drawers/Relationship/components/RelationshipContext/components/RequirementNameInput';
import useBlockControls from '@components/EntityDrawers/drawers/Relationship/components/RelationshipContext/hooks/useBlockControls';
import { RelationOption } from '@components/EntityDrawers/drawers/Relationship/constants/types';
import TextFieldSelect, {
  DEFAULT_PLACEHOLDER_VALUE,
} from '@components/Inputs/TextFieldSelect';
import { Fade, Stack, Switch, Typography } from '@mui/material';
import { transformArrayToOptions } from '@utils/helpers';

type Props = {
  mainLabel: string;
  underLabel: string;
  underPlaceholder: string;
  option: RelationOption;
  childOptions: RelationOption[];
};

const RequirementBlock: FC<Props> = ({
  mainLabel,
  underLabel,
  underPlaceholder,
  option: { id, name },
  childOptions,
}) => {
  const { isOn, value, handleToggle, handleChangeValue } = useBlockControls(id);

  const options = useMemo(
    () =>
      transformArrayToOptions(childOptions, {
        label: 'name',
        value: 'id',
      }),
    [childOptions],
  );

  return (
    <Wrapper>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{mainLabel}</Typography>

        <Switch
          value={isOn}
          checked={isOn}
          onChange={(_, checked) => handleToggle(checked)}
        />
      </Stack>

      <Fade in={isOn} hidden unmountOnExit>
        <Stack gap={4}>
          <RequirementNameInput value={name} />

          <TextFieldSelect
            label={underLabel}
            options={options}
            placeholder={underPlaceholder}
            maxMenuWidth={0}
            value={value ?? DEFAULT_PLACEHOLDER_VALUE}
            onChange={(e) => handleChangeValue(e.target.value)}
          />
        </Stack>
      </Fade>
    </Wrapper>
  );
};

export default RequirementBlock;
