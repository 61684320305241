import React, { ChangeEvent, FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Label from '@components/Inputs/Label';
import {
  Box,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { themePalette } from '@theme/muiTheme';

export type ChangeWrapper = (
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
) => (e: ChangeEvent<HTMLInputElement>) => void;

export type ControlledTextFieldProps = TextFieldProps & {
  name: string;
  changeWrapper?: ChangeWrapper;
  errorOff?: boolean;
  optionalLabel?: boolean;
  boxSx?: SxProps;
  tooltip?: string;
};

const ControlledTextField: FC<ControlledTextFieldProps> = ({
  name,
  changeWrapper,
  label,
  fullWidth = true,
  required,
  children,
  placeholder,
  defaultValue,
  helperText,
  error: propsError,
  errorOff,
  boxSx,
  onBlur: handleBlur,
  tooltip,
  optionalLabel,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, onBlur, ...field },
        fieldState: { error },
      }) => (
        <Box sx={{ width: '100%', ...boxSx }}>
          {label && (
            <Label required={required} tooltip={tooltip}>
              {label}

              {optionalLabel && (
                <Typography color="grey.800" sx={{ display: 'inline' }}>
                  &nbsp;&nbsp;(optional)
                </Typography>
              )}
            </Label>
          )}

          <TextField
            fullWidth={fullWidth}
            required={required}
            value={value ?? ''}
            onChange={changeWrapper ? changeWrapper(onChange) : onChange}
            helperText={error?.message || helperText}
            error={!errorOff && (!!error || propsError)}
            placeholder={placeholder}
            onBlur={(event) => {
              onBlur();
              handleBlur?.(event);
            }}
            inputProps={{
              sx: {
                '&::placeholder': {
                  color: themePalette.grey[800],
                  opacity: 1,
                },
              },
            }}
            {...field}
            {...props}
          >
            {children}
          </TextField>
        </Box>
      )}
    />
  );
};

export default ControlledTextField;
