import {
  ConceptualMeasurementObject,
  ConceptualSourceFormValues,
  ConceptualSourceObject,
} from '@components/EntityDrawers/constants/empty-objects';
import {
  CoreFormKeys,
  CoreFormKeysType,
} from '@components/EntityDrawers/constants/keys';
import { ConceptualKeys } from '@components/EntityDrawers/drawers/Conceptual/keys';
import { ShapeType } from '@constants/types';
import {
  MaxDescriptionLength,
  YupLink,
  YupString,
} from '@constants/validation';
import { ConceptualMeasurement } from '@store/services/nodes/types';
import { generateSchema, getDefs, getYups } from '@utils/drawers';
import { withId } from '@utils/helpers';
import * as yup from 'yup';

export type ConceptualFormValues = yup.InferType<typeof validationSchema> & {
  [ConceptualKeys.Sources]: ConceptualSourceFormValues[];
  [ConceptualKeys.Measurement]: ConceptualMeasurement[];
};

const sourceSchema = yup.object().shape({
  id: yup.string().required(),
  type: yup.string(),
  name: YupString({ allowParagraph: true }),
  justification_statement: yup.array().of(
    yup.object().shape({
      name: YupString({
        min: 1,
        max: MaxDescriptionLength,
        allowParagraph: true,
      }),
    }),
  ),
  link: YupLink(),
});

const measurementSchema = yup.object().shape({
  id: yup.string().required(),
  approach: YupString({ allowParagraph: true }),
  metric_type: YupString({ allowParagraph: true }),
  metric_output: YupString({ allowParagraph: true }),
  target: YupString({ allowParagraph: true }),
  primary_threshold: YupString({ allowParagraph: true }),
  secondary_threshold: YupString({ allowParagraph: true }),
});

const ConceptualShape = {
  [ConceptualKeys.Definition]: YupString({
    min: 1,
    max: MaxDescriptionLength,
    allowParagraph: true,
  }),
  [ConceptualKeys.Sources]: yup.array().of(sourceSchema),
  [ConceptualKeys.Measurement]: yup.array().of(measurementSchema),
} as const;

export const validationSchema = generateSchema<
  ShapeType<CoreFormKeysType> & typeof ConceptualShape
>({
  ...getYups(...CoreFormKeys),
  ...ConceptualShape,
});

export const defaultValues: ConceptualFormValues = {
  ...getDefs(...CoreFormKeys),
  [ConceptualKeys.Definition]: '',
  // @ts-ignore
  [ConceptualKeys.Sources]: [withId(ConceptualSourceObject)],
  [ConceptualKeys.Measurement]: [withId(ConceptualMeasurementObject)],
};
